<template>
  <div>
    <h2>{{ $t("user.setting-new-password.password-was-reset-by-message", { userName: resetBy.userName }) }}</h2>
    <new-password ref="newPasswordComponent"></new-password>
    <b-button type="submit" variant="primary" @click="onSubmitClick">{{
      $t("user.setting-new-password.set-password-caption")
    }}</b-button>
  </div>
</template>

<script>
import NewPassword from "@/components/user/NewPassword.vue";
import http from "@/http.js";
import auth from "@/services/auth.js";
import redirectUserToHisPageMixin from "@/mixins/redirect-user-to-his-page.js";
import { LOGIN } from "@/const/events.js";

export default {
  components: {
    newPassword: NewPassword
  },
  props: {
    changePasswordToken: {
      type: String,
      required: true
    }
  },
  mixins: [redirectUserToHisPageMixin],
  data() {
    return {
      isLoading: false,
      resetBy: { userName: "" }
    };
  },
  methods: {
    onSubmitClick() {
      var validationResult = this.$refs.newPasswordComponent.isValid;
      if (validationResult) {
        http
          .post("api/user/set-password", {
            changePasswordToken: this.changePasswordToken,
            newPassword: this.$refs.newPasswordComponent.password
          })
          .then(resp => {
            auth.set(resp.data);
            this.$bus.emit(LOGIN);
            this.redirectUserToHisPage();
          })
          .catch(() => alert(this.$t("user.setting-new-password.error-while-setting-password")));
      } else {
        alert(this.$t("user.setting-new-password.invalid-password"));
      }
    }
  },
  mounted() {
    this.isLoading = true;
    http
      .get(`api/user/${this.changePasswordToken}/reset-by`)
      .then(resp => {
        this.resetBy = resp.data;
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>
