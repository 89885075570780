<template>
  <b-container>
    <setting-new-password :changePasswordToken="$route.params.changePasswordToken"></setting-new-password>
  </b-container>
</template>

<script>
import SettingNewPassword from "@/components/user/SettingNewPassword.vue";
export default {
  components: {
    settingNewPassword: SettingNewPassword
  }
};
</script>
